html, body {
    margin: 0;
    padding: 0;
}


/* === LoginPage === */

.loginPage-button {
    background-color: #e7521f;
}

.loginPage-button:hover {
    background-color: #e72d00;
}

.loginPage-link {
    text-decoration: none;
}

.loginPage-link:hover {
    text-decoration: underline;
}


/* === Header === */

.header-logout-button:hover {
    background: #EEE;
}


/* === SearchForm === */

.search-form-button {
    background-color: #007bff;
}

.search-form-button:hover {
    background-color: #0056b3;
}


/* === DocumentList === */

.document-item {
    text-decoration: none;
}

.document-item:hover {
    text-decoration: underline;
}

.document-navigation-button {
    background-color: #007bff;
    cursor: pointer;
}

.document-navigation-button:hover {
    background-color: #0056b3;
}

.document-navigation-button:disabled {
    background-color: #ccc;
    cursor: default;
}
